var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { role: "main" } },
    [
      _c("ProjectsFilter", {
        attrs: { filter: _vm.filter },
        on: { change: _vm.changeFilter },
      }),
      _vm._v(" "),
      _c("section", { staticClass: "main__trends trends" }, [
        _c("div", { staticClass: "trends__container" }, [
          _c("div", { staticClass: "trends__header header-trends" }, [
            _c("div", { staticClass: "header-trends__article" }, [
              _c("h1", { staticClass: "trends__title" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.$t("main.h1")) + "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "trends__paragraph" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("main.h1_desc")) +
                    "\n            "
                ),
                !_vm.totalsLoading
                  ? _c("span", { staticClass: "highlighted" }, [
                      _vm._v(
                        "\n              $" +
                          _vm._s(_vm.$getBigNumber(_vm.totals.cap, 2)) +
                          "\n            "
                      ),
                    ])
                  : _c(
                      "span",
                      { staticClass: "highlighted" },
                      [
                        _c("Skeleton", {
                          attrs: { width: "40px", height: "20px" },
                        }),
                      ],
                      1
                    ),
                _vm._v(
                  ",\n            " +
                    _vm._s(_vm.$t("main.which_is")) +
                    "\n            "
                ),
                !_vm.totalsLoading
                  ? _c(
                      "span",
                      {
                        class: {
                          higher: _vm.totals.capPercent > 0,
                          lower: _vm.totals.capPercent < 0,
                        },
                      },
                      [
                        _vm.totals.capPercent > 0
                          ? _c("i", { staticClass: "bx bx-caret-up" })
                          : _vm.totals.capPercent < 0
                          ? _c("i", { staticClass: "bx bx-caret-down" })
                          : _vm._e(),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$priceFormat(_vm.totals.capPercent, 2)) +
                            "%\n              "
                        ),
                        _vm.totals.capPercent > 0
                          ? _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("main.increase")) +
                                  "\n              "
                              ),
                            ])
                          : _vm.totals.capPercent < 0
                          ? _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("main.decrease")) +
                                  "\n              "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _c(
                      "span",
                      [
                        _c("Skeleton", {
                          attrs: { width: "150px", height: "20px" },
                        }),
                      ],
                      1
                    ),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("main.h1_desc2")) +
                    ".\n            "
                ),
                _c(
                  "span",
                  { staticClass: "toggler", on: { click: _vm.toggleTotals } },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("main.read_more")) +
                        ".\n            "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              !_vm.totalsLoading
                ? _c(
                    "p",
                    { class: { "hidden-desc": true, _active: _vm.showTotals } },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("main.total_volume")) +
                          "\n            "
                      ),
                      _c("span", [
                        _vm._v(
                          "\n              $" +
                            _vm._s(_vm.$getBigNumber(_vm.totals.volume, 2)) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(
                        ".\n            " +
                          _vm._s(_vm.$t("main.total_defi")) +
                          "\n            "
                      ),
                      _c("span", [
                        _vm._v(
                          "\n              $" +
                            _vm._s(
                              _vm.$getBigNumber(_vm.totals.defiVolume, 2)
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("main.which_is")) +
                          "\n            "
                      ),
                      _c("span", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$priceFormat(_vm.totals.defiVolumePercent, 2)
                            ) +
                            "%\n            "
                        ),
                      ]),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("main.market_share")) +
                          ".\n          "
                      ),
                    ]
                  )
                : _c(
                    "p",
                    { class: { "hidden-desc": true, _active: _vm.showTotals } },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("main.total_volume")) +
                          "\n            "
                      ),
                      _c(
                        "span",
                        [
                          _c("Skeleton", {
                            attrs: { width: "40px", height: "20px" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(
                        ".\n            " +
                          _vm._s(_vm.$t("main.total_defi")) +
                          "\n            "
                      ),
                      _c(
                        "span",
                        [
                          _c("Skeleton", {
                            attrs: { width: "50px", height: "20px" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("main.which_is")) +
                          "\n            "
                      ),
                      _c(
                        "span",
                        [
                          _c("Skeleton", {
                            attrs: { width: "50px", height: "20px" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("main.market_share")) +
                          ".\n          "
                      ),
                    ]
                  ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                class: { "trends-switch": true, _active: _vm.highlitsActive },
                on: { click: _vm.toggleHighlights },
              },
              [
                _c("div", { staticClass: "trends-switch__label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("main.highlights")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "trends-switch__switch",
                    attrs: {
                      type: "button",
                      "aria-label": _vm.$t("main.toggle"),
                    },
                  },
                  [
                    _c("span"),
                    _vm._v(" "),
                    _c("nuxt-img", {
                      attrs: {
                        src: "/design/list-trends/switch.svg",
                        alt: _vm.$t("main.toggle_desc"),
                        title: _vm.$t("main.toggle"),
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                "trends-navigation toggable-content": true,
                _active: _vm.highlitsActive,
              },
            },
            [
              _c("div", { staticClass: "trends-navigation__list" }, [
                _c(
                  "div",
                  {
                    class: {
                      "trends-navigation__title": true,
                      _current: _vm.currentHighlight === "trending",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.pickHighlight("trending")
                      },
                    },
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", { staticClass: "main-trends__title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("main.trending")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "trends-navigation__title": true,
                      _current: _vm.currentHighlight === "biggest-gainers",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.pickHighlight("biggest-gainers")
                      },
                    },
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "main-trends__title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("main.gainers")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "trends-navigation__title": true,
                      _current: _vm.currentHighlight === "articles",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.pickHighlight("articles")
                      },
                    },
                  },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("div", { staticClass: "main-trends__title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("main.recent_articles")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "trends-navigation__select select": true,
                    "is-active": _vm.highlightsDropdown,
                  },
                },
                [
                  _c("div", { staticClass: "select__header" }, [
                    _vm.currentHighlight === "trending"
                      ? _c("div", { staticClass: "select__current" }, [
                          _vm._m(3),
                          _vm._v(" "),
                          _c("div", { staticClass: "main-trends__title" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("main.trending")) +
                                "\n              "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentHighlight === "biggest-gainers"
                      ? _c("div", { staticClass: "select__current" }, [
                          _vm._m(4),
                          _vm._v(" "),
                          _c("div", { staticClass: "main-trends__title" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("main.gainers")) +
                                "\n              "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentHighlight === "articles"
                      ? _c("div", { staticClass: "select__current" }, [
                          _vm._m(5),
                          _vm._v(" "),
                          _c("div", { staticClass: "main-trends__title" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("main.recent_articles")) +
                                "\n              "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "trends-navigation__spoiler",
                        attrs: { "aria-label": _vm.$t("common.dropdown") },
                        on: { click: _vm.toggleHighlightsSpoiler },
                      },
                      [_c("i", { staticClass: "bx bxs-down-arrow" })]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "select__body" }, [
                    _c(
                      "div",
                      {
                        staticClass: "select__item",
                        on: {
                          click: function ($event) {
                            return _vm.pickHighlight("trending")
                          },
                        },
                      },
                      [
                        _vm._m(6),
                        _vm._v(" "),
                        _c("div", { staticClass: "main-trends__title" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("main.trending")) +
                              "\n              "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "select__item",
                        on: {
                          click: function ($event) {
                            return _vm.pickHighlight("biggest-gainers")
                          },
                        },
                      },
                      [
                        _vm._m(7),
                        _vm._v(" "),
                        _c("div", { staticClass: "main-trends__title" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("main.gainers")) +
                              "\n              "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "select__item",
                        on: {
                          click: function ($event) {
                            return _vm.pickHighlight("articles")
                          },
                        },
                      },
                      [
                        _vm._m(8),
                        _vm._v(" "),
                        _c("div", { staticClass: "main-trends__title" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("main.recent_articles")) +
                              "\n              "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "trends-navigation__links" },
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "trends-navigation__link",
                      attrs: { to: _vm.localePath(`/${_vm.currentHighlight}`) },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("main.more")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "trends-navigation__arrow",
                      attrs: {
                        to: _vm.localePath(`/${_vm.currentHighlight}`),
                        "aria-label": _vm.$t("main.more"),
                      },
                    },
                    [_c("i", { staticClass: "bx bx-right-arrow-alt" })]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                "toggable-content trends__body main-trends": true,
                _active: _vm.highlitsActive,
              },
            },
            [
              _c(
                "div",
                {
                  class: {
                    "list-trends": true,
                    _current: _vm.currentHighlight === "trending",
                  },
                },
                [
                  _c("div", { staticClass: "list-trends__top" }, [
                    _c("div", { staticClass: "list-trends__title" }, [
                      _vm._m(9),
                      _vm._v(" "),
                      _c("div", { staticClass: "main-trends__title" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("main.trending")) +
                            "\n              "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "list-trends__links" },
                      [
                        _c(
                          "nuxt-link",
                          {
                            staticClass: "list-trends__link",
                            attrs: { to: _vm.localePath("/trending") },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("main.more")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "nuxt-link",
                          {
                            staticClass: "list-trends__arrow",
                            attrs: {
                              to: _vm.localePath("/trending"),
                              "aria-label": _vm.$t("main.more"),
                            },
                          },
                          [_c("i", { staticClass: "bx bx-right-arrow-alt" })]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.tops.trendings.length > 0 && !_vm.topsLoading
                    ? _c(
                        "div",
                        { staticClass: "list-trends__list" },
                        _vm._l(_vm.tops.trendings, function (trending, ind) {
                          return _c(
                            "div",
                            { key: ind, staticClass: "list-pos" },
                            [
                              _c("div", { staticClass: "list-pos__wrapper" }, [
                                _c("span", { staticClass: "list-pos__num" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(ind + 1) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "list-pos__main-info" },
                                  [
                                    _c(
                                      "nuxt-link",
                                      {
                                        staticClass: "list-pos__icon",
                                        attrs: {
                                          to: _vm.localePath(
                                            "/tokens/" + trending.slug
                                          ),
                                        },
                                      },
                                      [
                                        _c("nuxt-img", {
                                          attrs: {
                                            src: _vm.$getImage(
                                              trending.image.filepath,
                                              "projects"
                                            ),
                                            alt:
                                              trending.title +
                                              _vm.$t("common.at") +
                                              _vm.$config.appName,
                                            title: trending.title,
                                            quality: 80,
                                            format: "webp",
                                            placeholder: "",
                                            loading: "lazy",
                                          },
                                          on: {
                                            error: function ($event) {
                                              $event.target.src =
                                                _vm.$config.mediaUrl +
                                                "img/projects/default.webp"
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "nuxt-link",
                                      {
                                        staticClass: "list-pos__name",
                                        attrs: {
                                          to: _vm.localePath(
                                            "/tokens/" + trending.slug
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "list-pos__title" },
                                          [
                                            trending.premium !== null
                                              ? _c("i", {
                                                  staticClass:
                                                    "bx bxs-zap premium_icon",
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(trending.title) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        trending.token !== null
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "list-pos__slash",
                                              },
                                              [_vm._v("/")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        trending.token !== null
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "list-pos__short-name",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(trending.token) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    class: {
                                      "list-pos__rate pos-rate": true,
                                      plus: trending.difference > 0,
                                      minus: trending.difference < 0,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pos-rate__icon" },
                                      [
                                        trending.difference > 0
                                          ? _c("i", {
                                              staticClass: "bx bx-trending-up",
                                            })
                                          : trending.difference < 0
                                          ? _c("i", {
                                              staticClass:
                                                "bx bx-trending-down",
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "pos-rate__num" },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              trending.difference !== null
                                                ? _vm.$priceFormat(
                                                    trending.difference,
                                                    2
                                                  ) + "%"
                                                : "..."
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm.topsLoading || !_vm.pageInit
                    ? _c(
                        "div",
                        { staticClass: "list-trends__list" },
                        _vm._l(3, function (index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "list-pos" },
                            [
                              _c("div", { staticClass: "list-pos__wrapper" }, [
                                _c("span", { staticClass: "list-pos__num" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(index) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "list-pos__main-info" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "list-pos__icon" },
                                      [
                                        _c("Skeleton", {
                                          attrs: {
                                            circle: "",
                                            width: "16px",
                                            height: "16px",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "list-pos__name" },
                                      [
                                        _c("Skeleton", {
                                          staticClass: "list-pos__title",
                                          attrs: {
                                            width: "65px",
                                            height: "16px",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "list-pos__slash" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("Skeleton", {
                                          staticClass: "list-pos__short-name",
                                          attrs: {
                                            width: "30px",
                                            height: "16px",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "list-pos__rate pos-rate" },
                                  [
                                    _c("Skeleton", {
                                      attrs: { width: "50px", height: "16px" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        { staticClass: "list-trends__list not-found-area" },
                        [
                          _c("Empty"),
                          _vm._v(" "),
                          _c("div", { staticClass: "not-found-header" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("main.no_trendings")) +
                                "\n            "
                            ),
                          ]),
                        ],
                        1
                      ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "list-trends": true,
                    _current: _vm.currentHighlight === "biggest-gainers",
                  },
                },
                [
                  _c("div", { staticClass: "list-trends__top" }, [
                    _c("div", { staticClass: "list-trends__title" }, [
                      _vm._m(10),
                      _vm._v(" "),
                      _c("div", { staticClass: "main-trends__title" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("main.gainers")) +
                            "\n              "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "list-trends__links" },
                      [
                        _c(
                          "nuxt-link",
                          {
                            staticClass: "list-trends__link",
                            attrs: { to: _vm.localePath("/biggest-gainers") },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("main.more")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "nuxt-link",
                          {
                            staticClass: "list-trends__arrow",
                            attrs: {
                              to: _vm.localePath("/biggest-gainers"),
                              "aria-label": _vm.$t("main.more"),
                            },
                          },
                          [_c("i", { staticClass: "bx bx-right-arrow-alt" })]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.tops.gainers.length > 0 && !_vm.topsLoading
                    ? _c(
                        "div",
                        { staticClass: "list-trends__list" },
                        _vm._l(_vm.tops.gainers, function (gainer, ind) {
                          return _c(
                            "div",
                            { key: ind, staticClass: "list-pos" },
                            [
                              _c(
                                "div",
                                { staticClass: "list-pos__wrapper" },
                                [
                                  _c("span", { staticClass: "list-pos__num" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(ind + 1) +
                                        "\n                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "nuxt-link",
                                    {
                                      staticClass: "list-pos__icon",
                                      attrs: {
                                        to: _vm.localePath(
                                          "/tokens/" + gainer.slug
                                        ),
                                      },
                                    },
                                    [
                                      _c("nuxt-img", {
                                        attrs: {
                                          src: _vm.$getImage(
                                            gainer.image.filepath,
                                            "projects"
                                          ),
                                          alt:
                                            gainer.title +
                                            _vm.$t("common.at") +
                                            _vm.$config.appName,
                                          title: gainer.title,
                                          quality: 80,
                                          format: "webp",
                                          placeholder: "",
                                          loading: "lazy",
                                        },
                                        on: {
                                          error: function ($event) {
                                            $event.target.src =
                                              _vm.$config.mediaUrl +
                                              "img/projects/default.webp"
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "nuxt-link",
                                    {
                                      staticClass: "list-pos__name",
                                      attrs: {
                                        to: _vm.localePath(
                                          "/tokens/" + gainer.slug
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "list-pos__title" },
                                        [
                                          gainer.premium !== null
                                            ? _c("i", {
                                                staticClass:
                                                  "bx bxs-zap premium_icon",
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(gainer.title) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      gainer.token !== null
                                        ? _c(
                                            "span",
                                            { staticClass: "list-pos__slash" },
                                            [_vm._v("/")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      gainer.token !== null
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "list-pos__short-name",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(gainer.token) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        "list-pos__rate pos-rate": true,
                                        plus: gainer.difference > 0,
                                        minus: gainer.difference < 0,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "pos-rate__icon" },
                                        [
                                          gainer.difference > 0
                                            ? _c("i", {
                                                staticClass:
                                                  "bx bx-trending-up",
                                              })
                                            : gainer.difference < 0
                                            ? _c("i", {
                                                staticClass:
                                                  "bx bx-trending-down",
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "pos-rate__num" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                gainer.difference !== null
                                                  ? _vm.$priceFormat(
                                                      gainer.difference,
                                                      2
                                                    ) + "%"
                                                  : "..."
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm.topsLoading || !_vm.pageInit
                    ? _c(
                        "div",
                        { staticClass: "list-trends__list" },
                        _vm._l(3, function (index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "list-pos" },
                            [
                              _c("div", { staticClass: "list-pos__wrapper" }, [
                                _c("span", { staticClass: "list-pos__num" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(index) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "list-pos__main-info" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "list-pos__icon" },
                                      [
                                        _c("Skeleton", {
                                          attrs: {
                                            circle: "",
                                            width: "16px",
                                            height: "16px",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "list-pos__name" },
                                      [
                                        _c("Skeleton", {
                                          staticClass: "list-pos__title",
                                          attrs: {
                                            width: "65px",
                                            height: "16px",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "list-pos__slash" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("Skeleton", {
                                          staticClass: "list-pos__short-name",
                                          attrs: {
                                            width: "30px",
                                            height: "16px",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "list-pos__rate pos-rate" },
                                  [
                                    _c("Skeleton", {
                                      attrs: { width: "50px", height: "16px" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        { staticClass: "list-trends__list not-found-area" },
                        [
                          _c("Empty"),
                          _vm._v(" "),
                          _c("div", { staticClass: "not-found-header" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("main.no_gainers")) +
                                "\n            "
                            ),
                          ]),
                        ],
                        1
                      ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "list-trends": true,
                    _current: _vm.currentHighlight === "articles",
                  },
                },
                [
                  _c("div", { staticClass: "list-trends__top" }, [
                    _c("div", { staticClass: "list-trends__title" }, [
                      _vm._m(11),
                      _vm._v(" "),
                      _c("div", { staticClass: "main-trends__title" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("main.recent_articles")) +
                            "\n              "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "list-trends__links" },
                      [
                        _c(
                          "nuxt-link",
                          {
                            staticClass: "list-trends__link",
                            attrs: { to: _vm.localePath("/articles") },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("main.more")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "nuxt-link",
                          {
                            staticClass: "list-trends__arrow",
                            attrs: {
                              to: _vm.localePath("/articles"),
                              "aria-label": _vm.$t("main.more"),
                            },
                          },
                          [_c("i", { staticClass: "bx bx-right-arrow-alt" })]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.articles.length > 0 && !_vm.articlesLoading
                    ? _c(
                        "div",
                        { staticClass: "articles-area" },
                        _vm._l(_vm.articles, function (article, ind) {
                          return _c(
                            "div",
                            {
                              key: ind,
                              class: {
                                article: true,
                                active: ind === _vm.currentNews,
                              },
                            },
                            [
                              _c(
                                "nuxt-link",
                                {
                                  staticClass: "image-area",
                                  attrs: {
                                    to: _vm.localePath(
                                      "/articles/" + article.uri
                                    ),
                                  },
                                },
                                [
                                  _c("nuxt-img", {
                                    attrs: {
                                      placeholder: "",
                                      format: "webp",
                                      quality: "80",
                                      src: _vm.$getImage(article.image, "news"),
                                      alt:
                                        article.title +
                                        " " +
                                        _vm.$t("common.on_site"),
                                      title: article.title,
                                      loading: "lazy",
                                    },
                                    on: {
                                      error: function ($event) {
                                        $event.target.src =
                                          _vm.$config.mediaUrl +
                                          "img/news/default.webp"
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-area" },
                                [
                                  _c(
                                    "nuxt-link",
                                    {
                                      staticClass: "header",
                                      attrs: {
                                        to: _vm.localePath(
                                          "/articles/" + article.uri
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(article.title) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  article.fake_rate !== null
                                    ? _c("div", { staticClass: "labels" }, [
                                        _c(
                                          "div",
                                          {
                                            class: ["rate", article.sentiment],
                                          },
                                          [
                                            article.sentiment_rate > 50
                                              ? _c("i", {
                                                  staticClass: "bx bx-caret-up",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            article.sentiment_rate < 50
                                              ? _c("i", {
                                                  staticClass:
                                                    "bx bx-caret-down",
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    `news.${article.sentiment}`
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        article.mentioned_crypto !== null
                                          ? _c(
                                              "div",
                                              { staticClass: "tokens" },
                                              _vm._l(
                                                article.mentioned_crypto.split(
                                                  ","
                                                ),
                                                function (token, tind) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: tind,
                                                      staticClass: "token",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(token) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "data-area" }, [
                                    _c("p", { staticClass: "data" }, [
                                      _c("i", { staticClass: "bx bx-show" }),
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$getBigNumber(article.views)
                                          ) +
                                          "\n                  "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "data divider" }, [
                                      _vm._v(
                                        "\n                    •\n                  "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "data" }, [
                                      _c("i", {
                                        staticClass: "bx bx-calendar",
                                      }),
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$beautifyDate(
                                              article.created_at
                                            )
                                          ) +
                                          "\n                  "
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm.articlesLoading || !_vm.pageInit
                    ? _c("div", { staticClass: "articles-area" }, [
                        _c("div", { staticClass: "article active" }, [
                          _c(
                            "div",
                            { staticClass: "image-area" },
                            [
                              _c("Skeleton", {
                                attrs: { width: "100%", height: "100%" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-area" }, [
                            _c(
                              "span",
                              { staticClass: "category" },
                              [
                                _c("Skeleton", {
                                  attrs: { width: "50px", height: "12px" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "header" },
                              [
                                _c("Skeleton", {
                                  staticStyle: { "margin-bottom": ".25rem" },
                                  attrs: { width: "250px", height: "17px" },
                                }),
                                _vm._v(" "),
                                _c("Skeleton", {
                                  attrs: { width: "100px", height: "17px" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "data-area" }, [
                              _c(
                                "p",
                                { staticClass: "data" },
                                [
                                  _c("i", { staticClass: "bx bx-show" }),
                                  _vm._v(" "),
                                  _c("Skeleton", {
                                    attrs: { width: "35px", height: "10px" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "data divider" }, [
                                _vm._v(
                                  "\n                    •\n                  "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "data" },
                                [
                                  _c("i", { staticClass: "bx bx-calendar" }),
                                  _vm._v(" "),
                                  _c("Skeleton", {
                                    attrs: { width: "75px", height: "10px" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _c(
                        "div",
                        { staticClass: "list-trends__list not-found-area" },
                        [
                          _c("Empty"),
                          _vm._v(" "),
                          _c("div", { staticClass: "not-found-header" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("main.no_recent_news")) +
                                "\n            "
                            ),
                          ]),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _vm.articles.length > 0 && !_vm.articlesLoading
                    ? _c(
                        "div",
                        { staticClass: "pagination-dots" },
                        _vm._l(_vm.articles.length, function (ind) {
                          return _c("div", {
                            key: ind,
                            class: {
                              "pagination-dot": true,
                              active: ind - 1 === _vm.currentNews,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.goToNews(ind - 1)
                              },
                            },
                          })
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "section",
        {
          class: {
            "main__watchlist watchlist": true,
            watchlist_mod:
              _vm.filter.type === "exchanges" ||
              _vm.filter.type === "defi" ||
              _vm.filter.type === "cefi",
            watchlist_exchanges: _vm.filter.type === "exchanges",
            watchlist_defi:
              _vm.filter.type === "defi" || _vm.filter.type === "cefi",
          },
        },
        [
          _c(
            "div",
            { staticClass: "watchlist__container" },
            [
              _c("div", { staticClass: "watchlist__header watchlist-header" }, [
                _c(
                  "div",
                  { staticClass: "watchlist-title" },
                  [
                    _c(
                      "nuxt-link",
                      {
                        staticClass: "watchlist-title__wrapper",
                        attrs: { to: _vm.localePath("/account") },
                      },
                      [
                        _c("div", { staticClass: "watchlist-title__icon" }, [
                          _c("i", { staticClass: "bx bxs-star" }),
                        ]),
                        _vm._v(" "),
                        _c("h2", { staticClass: "watchlist-title__title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("common.watchlist")) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "watchlist-categories-spoiler select": true,
                      "is-active": _vm.dropdowns.currency,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "select__header",
                        on: {
                          click: function ($event) {
                            return _vm.toggleDropdown("currency")
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "select__current" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.currentCategory !== undefined
                                  ? _vm.currentCategory.label
                                  : "..."
                              ) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._m(12),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "select__body" }, [
                      _c(
                        "div",
                        { staticClass: "select__body-wrapper" },
                        _vm._l(_vm.categories, function (cat, ind) {
                          return _c(
                            "span",
                            {
                              key: ind,
                              staticClass: "select__item",
                              on: {
                                click: function ($event) {
                                  return _vm.selectType(cat.value)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(cat.label) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "watchlist-categories" },
                  _vm._l(_vm.categories, function (cat, ind) {
                    return _c(
                      "li",
                      {
                        key: ind,
                        class: {
                          "watchlist-categories__el": true,
                          "watchlist-categories__el_curent":
                            cat.value === _vm.filter.type,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.selectType(cat.value)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "watchlist-categories__text" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(cat.label) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "watchlist-showing" }, [
                  _c("span", { staticClass: "watchlist-showing__title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("filter.show_rows")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "watchlist-showing__select select": true,
                        "is-active": _vm.dropdowns.steps,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "select__header",
                          on: {
                            click: function ($event) {
                              return _vm.toggleDropdown("steps")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "select__current" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.filter.step) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._m(13),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "select__body" },
                        _vm._l(_vm.steps, function (step) {
                          return _c(
                            "span",
                            {
                              key: step,
                              staticClass: "select__item",
                              on: {
                                click: function ($event) {
                                  return _vm.changeStep(step)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(step) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "watchlist-filters" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "watchlist-filters__button watchlist-filters__button_customize disabled",
                      attrs: { type: "button" },
                    },
                    [
                      _vm._m(14),
                      _vm._v(" "),
                      _c("span", { staticClass: "watchlist-filters__name" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("filter.customize")) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "watchlist-filters__button watchlist-filters__button_filter",
                      attrs: { type: "button" },
                      on: { click: _vm.openFilter },
                    },
                    [
                      _vm._m(15),
                      _vm._v(" "),
                      _c("span", { staticClass: "watchlist-filters__name" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("filter.filters")) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "watchlist-orientation" }, [
                  _c(
                    "button",
                    {
                      staticClass: "watchlist-orientation__button _current",
                      attrs: {
                        type: "button",
                        "aria-label": _vm.$t("common.table_layout"),
                      },
                    },
                    [_c("i", { staticClass: "bx bx-menu" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "watchlist-orientation__button watchlist-orientation__button_disabled",
                      attrs: { "aria-label": _vm.$t("common.card_layout") },
                    },
                    [_c("i", { staticClass: "bx bxs-grid" })]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.filter.type === "exchanges"
                ? _c("ExchangesTable", {
                    attrs: {
                      projects: _vm.projects,
                      loading: _vm.loading || !_vm.pageInit,
                      filter: _vm.filter,
                    },
                    on: { changeSort: _vm.changeSort },
                  })
                : _vm.filter.type === "defi" || _vm.filter.type === "cefi"
                ? _c("DefiTable", {
                    attrs: {
                      projects: _vm.projects,
                      loading: _vm.loading || !_vm.pageInit,
                      filter: _vm.filter,
                    },
                    on: { changeSort: _vm.changeSort },
                  })
                : _c("ProjectsTable", {
                    attrs: {
                      projects: _vm.projects,
                      loading: _vm.loading || !_vm.pageInit,
                      filter: _vm.filter,
                    },
                    on: { changeSort: _vm.changeSort },
                  }),
              _vm._v(" "),
              _vm.projects.length > 0
                ? _c("Pagination", {
                    attrs: {
                      page: _vm.filter.page,
                      step: _vm.filter.step,
                      total: _vm.total,
                    },
                    on: { change: _vm.changePage, changeStep: _vm.changeStep },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "tooltips" }, [
            _c(
              "div",
              { attrs: { id: "volume_tooltip" } },
              [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("main.volume_tooltip_p1")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    attrs: {
                      to: _vm.localePath("/glossary/trade-volume"),
                      target: "_blank",
                      rel: "noopener",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("main.read_more")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "marketcap_tooltip" } },
              [
                _c("p", [_vm._v(_vm._s(_vm.$t("main.marketcap_tooltip_p1")))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$t("main.marketcap_tooltip_p2")))]),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    attrs: {
                      to: _vm.localePath(
                        "/glossary/market-capitalization-market-cap-mcap"
                      ),
                      target: "_blank",
                      rel: "noopener",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("main.read_more")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "supply_tooltip" } },
              [
                _c("p", [_vm._v(_vm._s(_vm.$t("main.supply_tooltip_p1")))]),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    attrs: {
                      to: _vm.localePath("/glossary/circulating-supply"),
                      target: "_blank",
                      rel: "noopener",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("main.read_more")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { attrs: { id: "exchange_tooltip" } }, [
              _c("div", { staticClass: "h6" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("main.exchange_tooltip_h1")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.$t("main.exchange_tooltip_p1")))]),
            ]),
            _vm._v(" "),
            _c("div", { attrs: { id: "effective_liquidity_tooltip" } }, [
              _c("p", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("main.effective_liquidity_tooltip_p1")) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { attrs: { id: "traffic_tooltip" } }, [
              _c("p", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("main.traffic_tooltip_p1")) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { attrs: { id: "markets_tooltip" } }, [
              _c("p", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("main.markets_tooltip_p1")) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "tvl_tooltip" } },
              [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("main.tvl_tooltip_p1")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    attrs: {
                      to: _vm.localePath("/glossary/circulating-supply"),
                      target: "_blank",
                      rel: "noopener",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("main.read_more")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { attrs: { id: "mcap_tvl_tooltip" } }, [
              _c("p", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("main.mcap_tvl_tooltip_p1")) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { attrs: { id: "score_tooltip" } }, [
              _c("div", { staticClass: "h6" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("main.exchange_tooltip_h1")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.$t("main.exchange_tooltip_p1")))]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("section", { staticClass: "main__first-use first-use" }, [
        _c("div", { staticClass: "first-use__container" }, [
          _c("div", { staticClass: "first-use__wrapper" }, [
            _c("div", { staticClass: "first-use__article first-use-article" }, [
              _c("div", { staticClass: "first-use-article__up" }, [
                _c("div", { staticClass: "first-use-article__title" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("main.be_first_to_know")) +
                      "\n              "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("main.app_header")) +
                        "\n              "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "first-use-article__buttons" }, [
                  _c(
                    "a",
                    {
                      staticClass: "first-use-article__button",
                      attrs: {
                        href: "https://play.google.com/store/apps/details?id=com.coins_rating.twa",
                        rel: "nofollow noopener noreferrer",
                        target: "_blank",
                        "aria-label": _vm.$t("common.download_app_google_play"),
                      },
                    },
                    [
                      _vm.theme === "dark"
                        ? _c("nuxt-img", {
                            staticClass: "button-dark",
                            attrs: {
                              src: "/design/first-use/pm-download-dark.svg",
                              alt: _vm.$t("common.googleplay_alt"),
                              title: _vm.$t("common.googleplay_title"),
                              laoding: "lazy",
                            },
                          })
                        : _c("nuxt-img", {
                            staticClass: "button-light",
                            attrs: {
                              src: "/design/first-use/pm-download-light.svg",
                              alt: _vm.$t("common.googleplay_alt"),
                              title: _vm.$t("common.googleplay_title"),
                              laoding: "lazy",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "first-use-article__button disabled",
                      attrs: {
                        "aria-label": _vm.$t("common.download_app_store"),
                      },
                    },
                    [
                      _vm.theme === "dark"
                        ? _c("nuxt-img", {
                            staticClass: "button-dark",
                            attrs: {
                              src: "/design/first-use/as-download-dark.svg",
                              alt: _vm.$t("common.appstore_alt"),
                              title: _vm.$t("common.appstore_title"),
                              laoding: "lazy",
                            },
                          })
                        : _c("nuxt-img", {
                            staticClass: "button-light",
                            attrs: {
                              src: "/design/first-use/as-download-light.svg",
                              alt: _vm.$t("common.appstore_alt"),
                              title: _vm.$t("common.appstore_title"),
                              laoding: "lazy",
                            },
                          }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "first-use-article__paragraph" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("main.news_text")) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _vm.userForm.sent === false
                ? _c("div", { staticClass: "first-use-form" }, [
                    _c(
                      "div",
                      {
                        class: {
                          "first-use-form__area": true,
                          "has-error": _vm.userForm.error.length > 0,
                        },
                      },
                      [
                        _vm._m(16),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.userForm.email,
                              expression: "userForm.email",
                            },
                          ],
                          staticClass: "first-use-form__input",
                          attrs: {
                            type: "email",
                            placeholder: "my_inbox@mail.com...",
                          },
                          domProps: { value: _vm.userForm.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.userForm,
                                "email",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.userForm.error.length > 0
                          ? _c("p", { staticClass: "error-hint bg-main" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.userForm.error) +
                                  "\n              "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        class: {
                          "first-use-form__button": true,
                          disabled: _vm.userForm.loading,
                        },
                        attrs: {
                          type: "button",
                          disabled: _vm.userForm.loading,
                        },
                        on: { click: _vm.subscribeEmail },
                      },
                      [
                        !_vm.userForm.loading
                          ? _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("main.subscribe")) +
                                  "\n              "
                              ),
                            ])
                          : _c("span", [
                              _c("i", {
                                staticClass: "bx bx-loader-alt loading",
                              }),
                            ]),
                      ]
                    ),
                  ])
                : _c("div", { staticClass: "first-use-form" }, [
                    _c("div", { staticClass: "flex-row success-form" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "checkmark mr-3",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 52 52",
                          },
                        },
                        [
                          _c("circle", {
                            staticClass: "checkmark__circle",
                            attrs: {
                              cx: "26",
                              cy: "26",
                              r: "25",
                              fill: "none",
                            },
                          }),
                          _c("path", {
                            staticClass: "checkmark__check",
                            attrs: {
                              fill: "none",
                              d: "M14.1 27.2l7.1 7.2 16.7-16.8",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-center" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("main.success_subscribe")) +
                            "\n              "
                        ),
                      ]),
                    ]),
                  ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "first-use__preview" },
              [
                _c("nuxt-img", {
                  attrs: {
                    src: "/design/first-use/currencies-illustration.svg",
                    alt: _vm.$t("main.currencies_alt"),
                    title: _vm.$t("main.currencies_title"),
                    placehodler: "",
                    loading: "lazy",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "trends-navigation__icon" }, [
      _c("i", { staticClass: "bx bxs-hot" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "trends-navigation__icon" }, [
      _c("i", { staticClass: "bx bx-bar-chart" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "trends-navigation__icon" }, [
      _c("i", { staticClass: "bx bxs-news" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "trends-navigation__icon" }, [
      _c("i", { staticClass: "bx bxs-hot" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "trends-navigation__icon" }, [
      _c("i", { staticClass: "bx bx-bar-chart" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "trends-navigation__icon" }, [
      _c("i", { staticClass: "bx bxs-news" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "trends-navigation__icon" }, [
      _c("i", { staticClass: "bx bxs-hot" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "trends-navigation__icon" }, [
      _c("i", { staticClass: "bx bx-bar-chart" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "trends-navigation__icon" }, [
      _c("i", { staticClass: "bx bxs-news" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list-trends__icon" }, [
      _c("i", { staticClass: "bx bxs-hot" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list-trends__icon" }, [
      _c("i", { staticClass: "bx bx-bar-chart" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list-trends__icon" }, [
      _c("i", { staticClass: "bx bxs-news" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "select__icon" }, [
      _c("i", { staticClass: "bx bxs-down-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "watchlist-showing__icon" }, [
      _c("i", { staticClass: "bx bxs-down-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "watchlist-filters__icon" }, [
      _c("i", { staticClass: "bx bxs-customize" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "watchlist-filters__icon" }, [
      _c("i", { staticClass: "bx bx-slider-alt" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "first-use-form__icon" }, [
      _c("i", { staticClass: "bx bxs-envelope" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }