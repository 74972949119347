<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0,512) scale(0.1,-0.1)" fill="currentColor" stroke="none">
      <path
        d="M529 4377 c-100 -28 -196 -109 -245 -207 -27 -52 -29 -64 -35 -245
          -4 -104 -8 -373 -8 -596 l-1 -406 31 -7 c17 -3 53 -6 80 -6 l49 0 1 418 c1
          229 4 494 8 589 6 170 6 172 36 215 20 28 49 52 84 71 l55 27 534 0 c382 0
          546 -3 574 -12 84 -25 108 -61 232 -338 l114 -255 933 -5 c1035 -6 957 0 1026
          -72 59 -61 63 -84 63 -333 l0 -225 -1367 0 c-840 0 -1391 -4 -1427 -10 -132
          -21 -235 -100 -290 -223 -18 -40 -154 -392 -302 -784 l-269 -712 -3 689 -2
          690 -80 0 -80 0 2 -807 3 -808 25 -59 c45 -110 157 -201 278 -226 31 -7 639
          -9 1762 -8 l1715 3 57 27 c63 29 139 95 173 148 12 20 157 389 321 820 l298
          785 0 90 c1 82 -2 96 -31 155 -40 81 -112 155 -181 187 -68 32 -146 43 -309
          43 l-131 0 -4 253 -3 252 -29 60 c-40 81 -127 166 -203 196 l-58 24 -891 3
          -891 3 -87 197 c-49 108 -102 218 -118 244 -36 58 -87 99 -165 136 l-58 27
          -570 2 c-431 2 -581 -1 -616 -10z m4069 -1575 c75 -40 129 -136 120 -216 -7
          -58 -583 -1573 -614 -1613 -14 -18 -44 -43 -67 -56 l-42 -22 -1699 -3 c-1173
          -1 -1710 1 -1737 8 -22 6 -53 23 -68 38 l-29 27 327 860 c180 473 333 871 340
          885 31 58 84 97 151 111 19 4 764 6 1655 5 l1620 -1 43 -23z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EmptyIcon'
}
</script>
