<template>
  <main class="main" role="main">
    <ProjectsFilter :filter="filter" @change="changeFilter" />
    <section class="main__trends trends">
      <div class="trends__container">
        <div class="trends__header header-trends">
          <div class="header-trends__article">
            <h1 class="trends__title">
              {{ $t('main.h1') }}
            </h1>
            <p class="trends__paragraph">
              {{ $t('main.h1_desc') }}
              <span v-if="!totalsLoading" class="highlighted">
                ${{ $getBigNumber(totals.cap, 2) }}
              </span>
              <span v-else class="highlighted">
                <Skeleton width="40px" height="20px" />
              </span>,
              {{ $t('main.which_is') }}
              <span v-if="!totalsLoading" :class="{'higher': totals.capPercent > 0, 'lower': totals.capPercent < 0}">
                <i v-if="totals.capPercent > 0" class="bx bx-caret-up"></i>
                <i v-else-if="totals.capPercent < 0" class="bx bx-caret-down"></i>
                {{ $priceFormat(totals.capPercent, 2) }}%
                <span v-if="totals.capPercent > 0">
                  {{ $t('main.increase') }}
                </span>
                <span v-else-if="totals.capPercent < 0">
                  {{ $t('main.decrease') }}
                </span>
              </span>
              <span v-else>
                <Skeleton width="150px" height="20px" />
              </span>
              {{ $t('main.h1_desc2') }}.
              <span class="toggler" @click="toggleTotals">
                {{ $t('main.read_more') }}.
              </span>
            </p>
            <p v-if="!totalsLoading" :class="{'hidden-desc': true, '_active': showTotals}">
              {{ $t('main.total_volume') }}
              <span>
                ${{ $getBigNumber(totals.volume, 2) }}
              </span>.
              {{ $t('main.total_defi') }}
              <span>
                ${{ $getBigNumber(totals.defiVolume, 2) }}
              </span>
              {{ $t('main.which_is') }}
              <span>
                {{ $priceFormat(totals.defiVolumePercent, 2) }}%
              </span>
              {{ $t('main.market_share') }}.
            </p>
            <p v-else :class="{'hidden-desc': true, '_active': showTotals}">
              {{ $t('main.total_volume') }}
              <span>
                <Skeleton width="40px" height="20px" />
              </span>.
              {{ $t('main.total_defi') }}
              <span>
                <Skeleton width="50px" height="20px" />
              </span>
              {{ $t('main.which_is') }}
              <span>
                <Skeleton width="50px" height="20px" />
              </span>
              {{ $t('main.market_share') }}.
            </p>
          </div>
          <div :class="{'trends-switch': true, '_active': highlitsActive}" @click="toggleHighlights">
            <div class="trends-switch__label">
              {{ $t('main.highlights') }}
            </div>
            <button type="button" class="trends-switch__switch" :aria-label="$t('main.toggle')">
              <span></span>
              <nuxt-img
                src="/design/list-trends/switch.svg"
                :alt="$t('main.toggle_desc')"
                :title="$t('main.toggle')"
              />
            </button>
          </div>
        </div>
        <div :class="{'trends-navigation toggable-content': true, '_active': highlitsActive}">
          <div class="trends-navigation__list">
            <div
              :class="{'trends-navigation__title': true, '_current': currentHighlight === 'trending'}"
              @click="pickHighlight('trending')"
            >
              <div class="trends-navigation__icon">
                <i class="bx bxs-hot"></i>
              </div>
              <div class="main-trends__title">
                {{ $t('main.trending') }}
              </div>
            </div>
            <div
              :class="{'trends-navigation__title': true, '_current': currentHighlight === 'biggest-gainers'}"
              @click="pickHighlight('biggest-gainers')"
            >
              <div class="trends-navigation__icon">
                <i class="bx bx-bar-chart"></i>
              </div>
              <div class="main-trends__title">
                {{ $t('main.gainers') }}
              </div>
            </div>
            <div
              :class="{'trends-navigation__title': true, '_current': currentHighlight === 'articles'}"
              @click="pickHighlight('articles')"
            >
              <div class="trends-navigation__icon">
                <i class="bx bxs-news"></i>
              </div>
              <div class="main-trends__title">
                {{ $t('main.recent_articles') }}
              </div>
            </div>
          </div>
          <div :class="{'trends-navigation__select select': true, 'is-active': highlightsDropdown}">
            <div class="select__header">
              <div v-if="currentHighlight === 'trending'" class="select__current">
                <div class="trends-navigation__icon">
                  <i class="bx bxs-hot"></i>
                </div>
                <div class="main-trends__title">
                  {{ $t('main.trending') }}
                </div>
              </div>
              <div v-if="currentHighlight === 'biggest-gainers'" class="select__current">
                <div class="trends-navigation__icon">
                  <i class="bx bx-bar-chart"></i>
                </div>
                <div class="main-trends__title">
                  {{ $t('main.gainers') }}
                </div>
              </div>
              <div v-if="currentHighlight === 'articles'" class="select__current">
                <div class="trends-navigation__icon">
                  <i class="bx bxs-news"></i>
                </div>
                <div class="main-trends__title">
                  {{ $t('main.recent_articles') }}
                </div>
              </div>
              <button class="trends-navigation__spoiler" :aria-label="$t('common.dropdown')" @click="toggleHighlightsSpoiler">
                <i class="bx bxs-down-arrow"></i>
              </button>
            </div>
            <div class="select__body">
              <div class="select__item" @click="pickHighlight('trending')">
                <div class="trends-navigation__icon">
                  <i class="bx bxs-hot"></i>
                </div>
                <div class="main-trends__title">
                  {{ $t('main.trending') }}
                </div>
              </div>
              <div class="select__item" @click="pickHighlight('biggest-gainers')">
                <div class="trends-navigation__icon">
                  <i class="bx bx-bar-chart"></i>
                </div>
                <div class="main-trends__title">
                  {{ $t('main.gainers') }}
                </div>
              </div>
              <div class="select__item" @click="pickHighlight('articles')">
                <div class="trends-navigation__icon">
                  <i class="bx bxs-news"></i>
                </div>
                <div class="main-trends__title">
                  {{ $t('main.recent_articles') }}
                </div>
              </div>
            </div>
          </div>
          <div class="trends-navigation__links">
            <nuxt-link :to="localePath(`/${currentHighlight}`)" class="trends-navigation__link">
              {{ $t('main.more') }}
            </nuxt-link>
            <nuxt-link :to="localePath(`/${currentHighlight}`)" class="trends-navigation__arrow" :aria-label="$t('main.more')">
              <i class="bx bx-right-arrow-alt"></i>
            </nuxt-link>
          </div>
        </div>
        <div :class="{'toggable-content trends__body main-trends': true, '_active': highlitsActive}">
          <div :class="{'list-trends': true, '_current': currentHighlight === 'trending'}">
            <div class="list-trends__top">
              <div class="list-trends__title">
                <div class="list-trends__icon">
                  <i class="bx bxs-hot"></i>
                </div>
                <div class="main-trends__title">
                  {{ $t('main.trending') }}
                </div>
              </div>
              <div class="list-trends__links">
                <nuxt-link :to="localePath('/trending')" class="list-trends__link">
                  {{ $t('main.more') }}
                </nuxt-link>
                <nuxt-link :to="localePath('/trending')" class="list-trends__arrow" :aria-label="$t('main.more')">
                  <i class="bx bx-right-arrow-alt"></i>
                </nuxt-link>
              </div>
            </div>
            <div v-if="tops.trendings.length > 0 && !topsLoading" class="list-trends__list">
              <div v-for="(trending, ind) in tops.trendings" :key="ind" class="list-pos">
                <div class="list-pos__wrapper">
                  <span class="list-pos__num">
                    {{ ind + 1 }}
                  </span>
                  <div class="list-pos__main-info">
                    <nuxt-link
                      :to="localePath('/tokens/' + trending.slug)"
                      class="list-pos__icon"
                    >
                      <nuxt-img
                        :src="$getImage(trending.image.filepath, 'projects')"
                        :alt="trending.title + $t('common.at') + $config.appName"
                        :title="trending.title"
                        :quality="80"
                        format="webp"
                        placeholder
                        loading="lazy"
                        @error="$event.target.src = $config.mediaUrl + 'img/projects/default.webp'"
                      />
                    </nuxt-link>
                    <nuxt-link
                      :to="localePath('/tokens/' + trending.slug)"
                      class="list-pos__name"
                    >
                      <span class="list-pos__title">
                        <i v-if="trending.premium !== null" class="bx bxs-zap premium_icon"></i>
                        {{ trending.title }}
                      </span>
                      <span v-if="trending.token !== null" class="list-pos__slash">/</span>
                      <span v-if="trending.token !== null" class="list-pos__short-name">
                        {{ trending.token }}
                      </span>
                    </nuxt-link>
                  </div>
                  <div :class="{'list-pos__rate pos-rate': true, 'plus': trending.difference > 0, 'minus': trending.difference < 0}">
                    <div class="pos-rate__icon">
                      <i v-if="trending.difference > 0" class="bx bx-trending-up"></i>
                      <i v-else-if="trending.difference < 0" class="bx bx-trending-down"></i>
                    </div>
                    <span class="pos-rate__num">
                      {{ trending.difference !== null ? $priceFormat(trending.difference, 2) + '%' : '...' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="topsLoading || !pageInit" class="list-trends__list">
              <div v-for="index in 3" :key="index" class="list-pos">
                <div class="list-pos__wrapper">
                  <span class="list-pos__num">
                    {{ index }}
                  </span>
                  <div class="list-pos__main-info">
                    <div class="list-pos__icon">
                      <Skeleton circle width="16px" height="16px" />
                    </div>
                    <div class="list-pos__name">
                      <Skeleton class="list-pos__title" width="65px" height="16px" />
                      <span class="list-pos__slash">/</span>
                      <Skeleton class="list-pos__short-name" width="30px" height="16px" />
                    </div>
                  </div>
                  <div class="list-pos__rate pos-rate">
                    <Skeleton width="50px" height="16px" />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="list-trends__list not-found-area">
              <Empty />
              <div class="not-found-header">
                {{ $t('main.no_trendings') }}
              </div>
            </div>
          </div>
          <div :class="{'list-trends': true, '_current': currentHighlight === 'biggest-gainers'}">
            <div class="list-trends__top">
              <div class="list-trends__title">
                <div class="list-trends__icon">
                  <i class="bx bx-bar-chart"></i>
                </div>
                <div class="main-trends__title">
                  {{ $t('main.gainers') }}
                </div>
              </div>
              <div class="list-trends__links">
                <nuxt-link :to="localePath('/biggest-gainers')" class="list-trends__link">
                  {{ $t('main.more') }}
                </nuxt-link>
                <nuxt-link :to="localePath('/biggest-gainers')" class="list-trends__arrow" :aria-label="$t('main.more')">
                  <i class="bx bx-right-arrow-alt"></i>
                </nuxt-link>
              </div>
            </div>
            <div v-if="tops.gainers.length > 0 && !topsLoading" class="list-trends__list">
              <div v-for="(gainer, ind) in tops.gainers" :key="ind" class="list-pos">
                <div class="list-pos__wrapper">
                  <span class="list-pos__num">
                    {{ ind + 1 }}
                  </span>
                  <nuxt-link
                    :to="localePath('/tokens/' + gainer.slug)"
                    class="list-pos__icon"
                  >
                    <nuxt-img
                      :src="$getImage(gainer.image.filepath, 'projects')"
                      :alt="gainer.title + $t('common.at') + $config.appName"
                      :title="gainer.title"
                      :quality="80"
                      format="webp"
                      placeholder
                      loading="lazy"
                      @error="$event.target.src = $config.mediaUrl + 'img/projects/default.webp'"
                    />
                  </nuxt-link>
                  <nuxt-link
                    :to="localePath('/tokens/' + gainer.slug)"
                    class="list-pos__name"
                  >
                    <span class="list-pos__title">
                      <i v-if="gainer.premium !== null" class="bx bxs-zap premium_icon"></i>
                      {{ gainer.title }}
                    </span>
                    <span v-if="gainer.token !== null" class="list-pos__slash">/</span>
                    <span v-if="gainer.token !== null" class="list-pos__short-name">
                      {{ gainer.token }}
                    </span>
                  </nuxt-link>
                  <div :class="{'list-pos__rate pos-rate': true, 'plus': gainer.difference > 0, 'minus': gainer.difference < 0}">
                    <div class="pos-rate__icon">
                      <i v-if="gainer.difference > 0" class="bx bx-trending-up"></i>
                      <i v-else-if="gainer.difference < 0" class="bx bx-trending-down"></i>
                    </div>
                    <span class="pos-rate__num">
                      {{ gainer.difference !== null ? $priceFormat(gainer.difference, 2) + '%' : '...' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="topsLoading || !pageInit" class="list-trends__list">
              <div v-for="index in 3" :key="index" class="list-pos">
                <div class="list-pos__wrapper">
                  <span class="list-pos__num">
                    {{ index }}
                  </span>
                  <div class="list-pos__main-info">
                    <div class="list-pos__icon">
                      <Skeleton circle width="16px" height="16px" />
                    </div>
                    <div class="list-pos__name">
                      <Skeleton class="list-pos__title" width="65px" height="16px" />
                      <span class="list-pos__slash">/</span>
                      <Skeleton class="list-pos__short-name" width="30px" height="16px" />
                    </div>
                  </div>
                  <div class="list-pos__rate pos-rate">
                    <Skeleton width="50px" height="16px" />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="list-trends__list not-found-area">
              <Empty />
              <div class="not-found-header">
                {{ $t('main.no_gainers') }}
              </div>
            </div>
          </div>
          <div :class="{'list-trends': true, '_current': currentHighlight === 'articles'}">
            <div class="list-trends__top">
              <div class="list-trends__title">
                <div class="list-trends__icon">
                  <i class="bx bxs-news"></i>
                </div>
                <div class="main-trends__title">
                  {{ $t('main.recent_articles') }}
                </div>
              </div>
              <div class="list-trends__links">
                <nuxt-link :to="localePath('/articles')" class="list-trends__link">
                  {{ $t('main.more') }}
                </nuxt-link>
                <nuxt-link :to="localePath('/articles')" class="list-trends__arrow" :aria-label="$t('main.more')">
                  <i class="bx bx-right-arrow-alt"></i>
                </nuxt-link>
              </div>
            </div>
            <div v-if="articles.length > 0 && !articlesLoading" class="articles-area">
              <div v-for="(article, ind) in articles" :key="ind" :class="{'article': true, 'active': ind === currentNews}">
                <nuxt-link :to="localePath('/articles/' + article.uri)" class="image-area">
                  <nuxt-img
                    placeholder
                    format="webp"
                    quality="80"
                    :src="$getImage(article.image, 'news')"
                    :alt="article.title + ' ' + $t('common.on_site')"
                    :title="article.title"
                    loading="lazy"
                    @error="$event.target.src = $config.mediaUrl + 'img/news/default.webp'"
                  />
                </nuxt-link>
                <div class="text-area">
                  <nuxt-link
                    :to="localePath('/articles/' + article.uri)"
                    class="header"
                  >
                    {{ article.title }}
                  </nuxt-link>
                  <div v-if="article.fake_rate !== null" class="labels">
                    <div :class="['rate', article.sentiment]">
                      <i v-if="article.sentiment_rate > 50" class="bx bx-caret-up"></i>
                      <i v-if="article.sentiment_rate < 50" class="bx bx-caret-down"></i>
                      {{ $t(`news.${article.sentiment}`) }}
                    </div>
                    <div v-if="article.mentioned_crypto !== null" class="tokens">
                      <div v-for="(token, tind) in article.mentioned_crypto.split(',')" :key="tind" class="token">
                        {{ token }}
                      </div>
                    </div>
                  </div>
                  <div class="data-area">
                    <p class="data">
                      <i class="bx bx-show"></i>
                      {{ $getBigNumber(article.views) }}
                    </p>
                    <p class="data divider">
                      &bull;
                    </p>
                    <p class="data">
                      <i class="bx bx-calendar"></i>
                      {{ $beautifyDate(article.created_at) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="articlesLoading || !pageInit" class="articles-area">
              <div class="article active">
                <div class="image-area">
                  <Skeleton width="100%" height="100%" />
                </div>
                <div class="text-area">
                  <span class="category">
                    <Skeleton width="50px" height="12px" />
                  </span>
                  <div class="header">
                    <Skeleton width="250px" height="17px" style="margin-bottom:.25rem" />
                    <Skeleton width="100px" height="17px" />
                  </div>
                  <div class="data-area">
                    <p class="data">
                      <i class="bx bx-show"></i>
                      <Skeleton width="35px" height="10px" />
                    </p>
                    <p class="data divider">
                      &bull;
                    </p>
                    <p class="data">
                      <i class="bx bx-calendar"></i>
                      <Skeleton width="75px" height="10px" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="list-trends__list not-found-area">
              <Empty />
              <div class="not-found-header">
                {{ $t('main.no_recent_news') }}
              </div>
            </div>
            <div v-if="articles.length > 0 && !articlesLoading" class="pagination-dots">
              <div v-for="ind in articles.length" :key="ind" :class="{'pagination-dot': true, 'active': (ind - 1) === currentNews}" @click="goToNews(ind - 1)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      :class="{'main__watchlist watchlist': true,
               'watchlist_mod': filter.type === 'exchanges' || filter.type === 'defi' || filter.type === 'cefi',
               'watchlist_exchanges': filter.type === 'exchanges',
               'watchlist_defi': filter.type === 'defi' || filter.type === 'cefi'
      }"
    >
      <div class="watchlist__container">
        <div class="watchlist__header watchlist-header">
          <div class="watchlist-title">
            <nuxt-link :to="localePath('/account')" class="watchlist-title__wrapper">
              <div class="watchlist-title__icon">
                <i class="bx bxs-star"></i>
              </div>
              <h2 class="watchlist-title__title">
                {{ $t('common.watchlist') }}
              </h2>
            </nuxt-link>
          </div>
          <div :class="{'watchlist-categories-spoiler select': true, 'is-active': dropdowns.currency}">
            <div class="select__header" @click="toggleDropdown('currency')">
              <div class="select__current">
                {{ currentCategory !== undefined ? currentCategory.label : '...' }}
              </div>
              <div class="select__icon">
                <i class="bx bxs-down-arrow"></i>
              </div>
            </div>
            <div class="select__body">
              <div class="select__body-wrapper">
                <span v-for="(cat, ind) in categories" :key="ind" class="select__item" @click="selectType(cat.value)">
                  {{ cat.label }}
                </span>
              </div>
            </div>
          </div>
          <ul class="watchlist-categories">
            <li
              v-for="(cat, ind) in categories"
              :key="ind"
              :class="{'watchlist-categories__el': true, 'watchlist-categories__el_curent': cat.value === filter.type}"
              @click="selectType(cat.value)"
            >
              <span class="watchlist-categories__text">
                {{ cat.label }}
              </span>
            </li>
          </ul>
          <div class="watchlist-showing">
            <span class="watchlist-showing__title">
              {{ $t('filter.show_rows') }}
            </span>
            <div :class="{'watchlist-showing__select select': true, 'is-active': dropdowns.steps}">
              <div class="select__header" @click="toggleDropdown('steps')">
                <div class="select__current">
                  {{ filter.step }}
                </div>
                <div class="watchlist-showing__icon">
                  <i class="bx bxs-down-arrow"></i>
                </div>
              </div>
              <div class="select__body">
                <span v-for="step in steps" :key="step" class="select__item" @click="changeStep(step)">
                  {{ step }}
                </span>
              </div>
            </div>
          </div>
          <div class="watchlist-filters">
            <button type="button" class="watchlist-filters__button watchlist-filters__button_customize disabled">
              <span class="watchlist-filters__icon">
                <i class="bx bxs-customize"></i>
              </span>
              <span class="watchlist-filters__name">
                {{ $t('filter.customize') }}
              </span>
            </button>
            <button type="button" class="watchlist-filters__button watchlist-filters__button_filter" @click="openFilter">
              <span class="watchlist-filters__icon">
                <i class="bx bx-slider-alt"></i>
              </span>
              <span class="watchlist-filters__name">
                {{ $t('filter.filters') }}
              </span>
            </button>
          </div>
          <div class="watchlist-orientation">
            <button type="button" class="watchlist-orientation__button _current" :aria-label="$t('common.table_layout')">
              <i class="bx bx-menu"></i>
            </button>
            <button class="watchlist-orientation__button watchlist-orientation__button_disabled" :aria-label="$t('common.card_layout')">
              <i class="bx bxs-grid"></i>
            </button>
          </div>
        </div>
        <ExchangesTable v-if="filter.type === 'exchanges'" :projects="projects" :loading="loading || !pageInit" :filter="filter" @changeSort="changeSort" />
        <DefiTable v-else-if="filter.type === 'defi' || filter.type === 'cefi'" :projects="projects" :loading="loading || !pageInit" :filter="filter" @changeSort="changeSort" />
        <ProjectsTable v-else :projects="projects" :loading="loading || !pageInit" :filter="filter" @changeSort="changeSort" />
        <Pagination
          v-if="projects.length > 0"
          :page="filter.page"
          :step="filter.step"
          :total="total"
          @change="changePage"
          @changeStep="changeStep"
        />
      </div>
      <div class="tooltips">
        <div id="volume_tooltip">
          <p>
            {{ $t('main.volume_tooltip_p1') }}
          </p>
          <nuxt-link :to="localePath('/glossary/trade-volume')" target="_blank" rel="noopener">
            {{ $t('main.read_more') }}
          </nuxt-link>
        </div>
        <div id="marketcap_tooltip">
          <p>{{ $t('main.marketcap_tooltip_p1') }}</p>
          <p>{{ $t('main.marketcap_tooltip_p2') }}</p>
          <nuxt-link :to="localePath('/glossary/market-capitalization-market-cap-mcap')" target="_blank" rel="noopener">
            {{ $t('main.read_more') }}
          </nuxt-link>
        </div>
        <div id="supply_tooltip">
          <p>{{ $t('main.supply_tooltip_p1') }}</p>
          <nuxt-link :to="localePath('/glossary/circulating-supply')" target="_blank" rel="noopener">
            {{ $t('main.read_more') }}
          </nuxt-link>
        </div>
        <div id="exchange_tooltip">
          <div class="h6">
            {{ $t('main.exchange_tooltip_h1') }}
          </div>
          <p>{{ $t('main.exchange_tooltip_p1') }}</p>
        </div>
        <div id="effective_liquidity_tooltip">
          <p>
            {{ $t('main.effective_liquidity_tooltip_p1') }}
          </p>
        </div>
        <div id="traffic_tooltip">
          <p>
            {{ $t('main.traffic_tooltip_p1') }}
          </p>
        </div>
        <div id="markets_tooltip">
          <p>
            {{ $t('main.markets_tooltip_p1') }}
          </p>
        </div>
        <div id="tvl_tooltip">
          <p>
            {{ $t('main.tvl_tooltip_p1') }}
          </p>
          <nuxt-link :to="localePath('/glossary/circulating-supply')" target="_blank" rel="noopener">
            {{ $t('main.read_more') }}
          </nuxt-link>
        </div>
        <div id="mcap_tvl_tooltip">
          <p>
            {{ $t('main.mcap_tvl_tooltip_p1') }}
          </p>
        </div>
        <div id="score_tooltip">
          <div class="h6">
            {{ $t('main.exchange_tooltip_h1') }}
          </div>
          <p>{{ $t('main.exchange_tooltip_p1') }}</p>
        </div>
      </div>
    </section>

    <section class="main__first-use first-use">
      <div class="first-use__container">
        <div class="first-use__wrapper">
          <div class="first-use__article first-use-article">
            <div class="first-use-article__up">
              <div class="first-use-article__title">
                {{ $t('main.be_first_to_know') }}
                <br>
                <span>
                  {{ $t('main.app_header') }}
                </span>
              </div>
              <div class="first-use-article__buttons">
                <a href="https://play.google.com/store/apps/details?id=com.coins_rating.twa" rel="nofollow noopener noreferrer" target="_blank" class="first-use-article__button" :aria-label="$t('common.download_app_google_play')">
                  <nuxt-img
                    v-if="theme === 'dark'"
                    src="/design/first-use/pm-download-dark.svg"
                    :alt="$t('common.googleplay_alt')"
                    :title="$t('common.googleplay_title')"
                    class="button-dark"
                    laoding="lazy"
                  />
                  <nuxt-img
                    v-else
                    src="/design/first-use/pm-download-light.svg"
                    :alt="$t('common.googleplay_alt')"
                    :title="$t('common.googleplay_title')"
                    class="button-light"
                    laoding="lazy"
                  />
                </a>
                <div class="first-use-article__button disabled" :aria-label="$t('common.download_app_store')">
                  <nuxt-img
                    v-if="theme === 'dark'"
                    src="/design/first-use/as-download-dark.svg"
                    :alt="$t('common.appstore_alt')"
                    :title="$t('common.appstore_title')"
                    class="button-dark"
                    laoding="lazy"
                  />
                  <nuxt-img
                    v-else
                    src="/design/first-use/as-download-light.svg"
                    :alt="$t('common.appstore_alt')"
                    :title="$t('common.appstore_title')"
                    class="button-light"
                    laoding="lazy"
                  />
                </div>
              </div>
            </div>
            <p class="first-use-article__paragraph">
              {{ $t('main.news_text') }}
            </p>
            <div v-if="userForm.sent === false" class="first-use-form">
              <div :class="{'first-use-form__area': true, 'has-error': userForm.error.length > 0}">
                <div class="first-use-form__icon">
                  <i class="bx bxs-envelope"></i>
                </div>
                <input
                  v-model="userForm.email"
                  type="email"
                  class="first-use-form__input"
                  placeholder="my_inbox@mail.com..."
                >
                <p v-if="userForm.error.length > 0" class="error-hint bg-main">
                  {{ userForm.error }}
                </p>
              </div>
              <button type="button" :class="{'first-use-form__button': true, 'disabled': userForm.loading}" :disabled="userForm.loading" @click="subscribeEmail">
                <span v-if="!userForm.loading">
                  {{ $t('main.subscribe') }}
                </span>
                <span v-else>
                  <i class="bx bx-loader-alt loading"></i>
                </span>
              </button>
            </div>
            <div v-else class="first-use-form">
              <div class="flex-row success-form">
                <svg class="checkmark mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                <p class="text-center">
                  {{ $t('main.success_subscribe') }}
                </p>
              </div>
            </div>
          </div>
          <div class="first-use__preview">
            <nuxt-img
              src="/design/first-use/currencies-illustration.svg"
              :alt="$t('main.currencies_alt')"
              :title="$t('main.currencies_title')"
              placehodler
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import {
  hydrateWhenIdle
} from 'vue-lazy-hydration'

import { Skeleton } from 'vue-loading-skeleton'

import Empty from '~/components/misc/Empty'

export default {
  name: 'MainPageComponent',
  components: {
    DefiTable: hydrateWhenIdle(() => import('~/components/tables/DefiTable')),
    Empty,
    ExchangesTable: hydrateWhenIdle(() => import('~/components/tables/ExchangesTable')),
    Pagination: hydrateWhenIdle(() => import('~/components/filter/Pagination')),
    ProjectsTable: hydrateWhenIdle(() => import('~/components/tables/ProjectsTable')),
    ProjectsFilter: hydrateWhenIdle(() => import('~/components/modals/ProjectsFilter')),
    Skeleton
  },
  data() {
    const highlitsCookie = this.$cookies.get('highlights_active') !== undefined ? this.$cookies.get('highlights_active') : 'active'
    return {
      userForm: {
        email: '',
        error: '',
        sent: false
      },
      currentNews: 0,
      marketCapMax: 12000000000000,
      priceMax: 1000000,
      volumeMax: 99000000000,
      priceChangeMax: 1000,
      supplyMax: 1200000000000,
      filter: {
        page: 1,
        step: 50,
        algorithm: '',
        category: '',
        marketCap: '',
        order: 'ASC',
        orderField: 'cmc_rank',
        platform: '',
        price: '',
        search: '',
        supply: '',
        type: 'all',
        volume: '',
        marketCapPip: null,
        marketCapMin: 0,
        marketCapMax: 12000000000000,
        pricePip: null,
        priceMin: 0,
        priceMax: 1000000,
        supplyPip: null,
        supplyMin: 0,
        supplyMax: 1200000000000,
        volumePip: null,
        volumeMin: 0,
        volumeMax: 99000000000
      },
      categories: [
        {
          label: this.$t('filter.coins'),
          value: 'all'
        },
        {
          label: this.$t('filter.exchanges'),
          value: 'exchanges'
        },
        {
          label: this.$t('filter.defi'),
          value: 'defi'
        },
        {
          label: this.$t('filter.cefi'),
          value: 'cefi'
        },
        {
          label: this.$t('filter.token_sale'),
          value: 'tokens'
        }
      ],
      highlitsActive: highlitsCookie === 'active',
      pageInit: false,
      showTotals: false,
      highlightsDropdown: false,
      currentHighlight: 'trending',
      dropdowns: {
        currency: false,
        steps: false
      }
    }
  },
  async fetch({ store, error, route }) {
    try {
      const pageName = route.name.split('___')
      const seoData = store.getters['seos/seosByUri'](pageName[0])
      if (seoData === null) {
        await store.dispatch('seos/fetchSeos', {
          uri: route.name
        })
      }
    } catch (err) {
      error(err)
    }
  },
  head({ $config: { appUrl } }) {
    return {
      title: this.meta ? this.meta.title : this.$t('common.launching'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.meta ? this.meta.description : ''
        },
        {
          hid: 'og-description',
          name: 'og:description',
          content: this.meta ? this.meta.description : ''
        },
        {
          hid: 'og-title',
          name: 'og:title',
          content: this.meta
            ? this.meta.title
            : this.$t('common.launching')
        },
        {
          hid: 'og-image',
          name: 'og:image',
          content: this.meta
            ? this.meta.image
            : appUrl + 'seo/default.jpg'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.meta ? this.meta.keyword : ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['steps', 'theme']),
    ...mapGetters('projects', [
      'tops',
      'totals',
      'topsLoading',
      'totalsLoading',
      'projects',
      'total',
      'loading'
    ]),
    ...mapGetters('seos', [
      'seosByUri'
    ]),
    ...mapGetters('news', [
      'articles'
    ]),
    articlesLoading() {
      return this.$store.getters['news/loading']
    },
    meta() {
      const pageName = this.$nuxt.$route.name.split('___')
      return this.seosByUri(pageName[0])
    },
    currentCategory() {
      return this.categories.find(itm => itm.value === this.filter.type)
    }
  },
  async mounted() {
    this.fetchArticles({
      page: 1,
      step: 5
    })
    await this.fetchTotals()
    await this.fetchTops()
    this.pageInit = true
    this.loadWithFilter()
    window.addEventListener('click', this.handleClickEvents)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickEvents)
  },
  methods: {
    ...mapActions('projects', [
      'fetchTops',
      'fetchTotals',
      'fetchProjects'
    ]),
    ...mapActions('support', [
      'sendSubscribe'
    ]),
    ...mapActions('modal', [
      'changeFilterState'
    ]),
    ...mapActions('news', [
      'fetchArticles'
    ]),
    toggleTotals() {
      this.showTotals = !this.showTotals
    },
    toggleHighlights() {
      this.highlitsActive = !this.highlitsActive
      this.$cookies.set('highlights_active', this.highlitsActive ? 'active' : 'disabled')
    },
    openFilter() {
      this.changeFilterState({
        state: true
      })
      document.querySelector('body').classList.add('scroll-none')
    },
    changeSort(newOrder) {
      this.filter.order = this.filter.orderField.toLowerCase() === newOrder.toLowerCase()
        ? (this.filter.order === 'ASC' ? 'DESC' : 'ASC')
        : 'DESC'
      this.filter.orderField = newOrder
      this.reloadProjects()
    },
    changePage(newPage) {
      this.filter.page = newPage
      this.loadWithFilter()
    },
    changeFilter(filterProps) {
      this.filter = filterProps
      this.reloadProjects()
    },
    changeStep(newStep) {
      const itemsLoaded = this.filter.page * this.filter.step
      this.filter.step = newStep
      this.filter.page = Math.ceil(itemsLoaded / parseFloat(newStep))
      this.loadWithFilter()
    },
    async selectType(newType) {
      if (newType.toLowerCase() !== this.filter.type.toLowerCase()) {
        this.filter.type = newType.toLowerCase()
        await this.changeType()
        this.reloadProjects()
      }
    },
    changeType() {
      switch (this.filter.type) {
        case 'all':
          this.filter.step = 50
          this.filter.order = 'ASC'
          this.filter.orderField = 'cmc_rank'
          break
        case 'exchanges':
          this.filter.step = 50
          this.filter.order = 'DESC'
          this.filter.orderField = 'rating'
          break
        case 'defi':
        case 'cefi':
          this.filter.step = 100
          this.filter.order = 'DESC'
          this.filter.orderField = 'tvl_desc'
          break
        case 'tokens':
          this.filter.step = 50
          this.filter.order = 'DESC'
          this.filter.orderField = 'rating'
          break
      }
      this.closeDropdowns()
    },
    reloadProjects() {
      this.filter.page = 1
      this.loadWithFilter()
    },
    loadWithFilter() {
      this.filter.marketCap = this.filter.marketCapMin + '-' + this.filter.marketCapMax
      this.filter.price = this.filter.priceMin + '-' + this.filter.priceMax
      this.filter.volume = this.filter.volumeMin + '-' + this.filter.volumeMax
      this.filter.supply = this.filter.supplyMin + '-' + this.filter.supplyMax
      this.fetchProjects(this.filter)
    },
    subscribeEmail() {
      this.userForm.loading = true
      this.sendSubscribe({
        email: this.userForm.email
      })
        .then(() => {
          this.userForm.loading = false
          this.userForm.sent = true
        })
        .catch((err) => {
          this.userForm.loading = false
          console.err(err)
          if (err.response) {
            this.userForm.error = this.$getErrorsText(err.response.data)
          }
        })
    },
    toggleHighlightsSpoiler() {
      this.highlightsDropdown = !this.highlightsDropdown
    },
    pickHighlight(newHighlight) {
      if (newHighlight !== undefined && newHighlight !== null && newHighlight.toLowerCase() !== this.currentHighlight.toLowerCase()) {
        this.currentHighlight = newHighlight
      }
    },
    toggleDropdown(dropdownName) {
      if (this.dropdowns[dropdownName] !== undefined) {
        this.closeDropdowns()
        this.$set(this.dropdowns, dropdownName, !this.dropdowns[dropdownName])
      }
    },
    handleClickEvents(evt) {
      if (
        !evt.target.closest('.select') &&
          !evt.target.closest('.select__body') &&
          !evt.target.closest('.select__current') &&
          !evt.target.closest('.select__header')
      ) {
        this.closeDropdowns()
      }
    },
    closeDropdowns() {
      for (const prop in this.dropdowns) {
        this.$set(this.dropdowns, prop, false)
      }
    },
    goToNews(newIndex) {
      if (!isNaN(newIndex) && parseInt(newIndex, 10) !== this.currentNews) {
        this.currentNews = parseInt(newIndex, 10)
      }
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/main.css');
</style>
